// Polyfill from https://github.com/ungap/random-uuid
// TODO - Remove this once our usage of Safari 15.3 and lower is low enough.
if (window && window.crypto && typeof window.crypto.randomUUID !== 'function') {
  window.crypto.randomUUID = function randomUUID() {
    return (
        // eslint-disable-next-line no-magic-numbers
      [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g,
        // eslint-disable-next-line no-magic-numbers
      c => (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };
}

if (!Array.prototype.at) {
  require('core-js/features/array/at.js');
}
