const scriptData = require('../../util/script_data.js');
const flipperFlags = scriptData.getData('flipper-flags');
const fontDiscoveryConfig = scriptData.getData('font-discovery-config');

/**
 * A service used to retrieve family matches from the backend.
 * @ngInject
 */
function FamilyAutocompleteSearchService() {
  return {
    /**
     * Get a list of family matches.
     *
     * @param {String} query
     * @return {Promise}
     */
    get: async function (query) {
      const fontDiscoverySearchEnabled = flipperFlags?.EnableFontDiscoverySearch;
      if (fontDiscoverySearchEnabled) {
        const result = await this.getHighlightAutocompleteResults(query);
        return result;
      } else {
        const result = await this.getLegacyAutocompleteResults(query);
        return result;
      }
    },

    getLegacyAutocompleteResults: async function (query) {
      try {
        const response = await fetch('/search/autocomplete?query=' + query);
        if (!response.ok) {
          console.log('Failed to fetch legacy autocomplete results', response.status, response.statusText);
          return Promise.resolve({ data: [] });
        }
        const data = await response.json();
        return Promise.resolve({ data: data });
      } catch (error) {
        console.log('Failed to fetch legacy autocomplete results');
        return Promise.resolve({ data: [] });
      }
    },

    getHighlightAutocompleteResults: async function (query) {
      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': fontDiscoveryConfig.apiKey
      };

      const options = {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({query: query, page_size: 12})
      };

      try {
        const response = await fetch(fontDiscoveryConfig.autocompleteUrl, options);
        if (!response.ok) {
          console.log('Failed to fetch autocomplete results', response.status, response.statusText);
          return Promise.resolve({ data: [] });
        }

        const data = await response.json();
        return Promise.resolve({ data: data.results });
      } catch (error) {
        console.log('Failed to fetch autocomplete results');
        return Promise.resolve({ data:  [] });
      }
    },
    /**
     * Returns hyperlink for the result item
     * @param resultItem
     * @returns {string}
     */
    getItemHyperlink: function(resultItem) {
      return '/fonts/' + resultItem.slug;
    }
  };
}

module.exports = FamilyAutocompleteSearchService;
