import { enableFlag } from '@adobe-fonts/site/lib/FeatureFlag.js';
import { getData } from './script_data.js';

export const initFlipper = () => {
  const flipperFlags = getData('flipper-flags');

  for (const [key, value] of Object.entries(flipperFlags || {})) {
    if (value) {
      enableFlag(key);
    }
  }
};
