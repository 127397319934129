const _ = require('underscore');

const {
  decrementSyncUsage,
  getPlan,
  getSyncOverageAmount,
  incrementSyncUsage,
  isInOverage
} = require('../../util/user_state.js');

const DESKTOP_FONT_SELECTIONS_ENDPOINT = '/desktop_font_selections';

/**
 * @ngInject
 */
function SyncService($http, $q, NewrelicService) {
  return {
    addSyncedVariations: addSyncedVariations,
    removeSyncedVariations: removeSyncedVariations
  };

  /**
  * Adds a variation to the user's synced variations.
  *
  * @param {Array} variationIds
  * @param {Object} params
  * @param {Number} toSyncCount
  * @returns {angular.Promise}
  */
  function addSyncedVariations(variationIds, params, toSyncCount) {
    var wasInOverage = isInOverage();
    var data = _.extend({
      'add_font_ids': variationIds
    }, params || {});
    return $http({
      // Preserve the data format that's being used with the existing sync
      // endpoint.
      data: {
        data: JSON.stringify(data)
      },
      method: 'PUT',
      url: DESKTOP_FONT_SELECTIONS_ENDPOINT
    }).then(function (response) {
      return $q(function (resolve) {
        if ('data' in response && response.data['added_font_ids'].length > 0) {
          incrementSyncUsage(response.data['added_font_ids'].length);
        }
        NewrelicService.addPageAction('typekit.click.sync-add-fonts', {
          fontCount: variationIds.length,
          royaltyFontCount: response.data['added_paid_count'],
          toSyncCount: toSyncCount,
          plan: getPlan(),
          enteredOverage: (!wasInOverage && isInOverage()),
          inOverage: isInOverage(),
          overageAmount: getSyncOverageAmount(),
          fromFontpack: !!(params || {}).fontpack_id
        });
        resolve(response);
      });
    });
  }

  /**
   * Removes a variation from the user's synced variations.
   *
   * @param {Object} params
   * @returns {angular.Promise}
   */
  function removeSyncedVariations(variationIds, params) {
    var wasInOverage = isInOverage();
    var data = _.extend({
      'remove_font_ids': variationIds
    }, params || {});
    return $http({
      // Preserve the data format that's being used with the existing sync
      // endpoint.
      data: {
        data: JSON.stringify(data)
      },
      method: 'PUT',
      url: DESKTOP_FONT_SELECTIONS_ENDPOINT
    }).then(function (response) {
      return $q(function (resolve) {
        if ('data' in response && response.data['removed_font_ids'].length > 0) {
          decrementSyncUsage(response.data['removed_font_ids'].length);
        }
        NewrelicService.addPageAction('typekit.click.sync-remove-fonts', {
          fontCount: variationIds.length,
          royaltyFontCount: response.data['removed_paid_count'],
          plan: getPlan(),
          exitedOverage: (wasInOverage && !isInOverage()),
          inOverage: isInOverage(),
          overageAmount: getSyncOverageAmount()
        });
        resolve(response);
      });
    });
  }
}

module.exports = SyncService;
