/**
 * Controller for parent element of <adobe-fonts-auto-complete>.
 * Configures search service to be passed to <adobe-fonts-auto-complete>
 *
 * @ngInject
 */
function FamilyAutoCompleteSearchController(FamilyAutocompleteSearchService) {
  this.autocompleteService = FamilyAutocompleteSearchService;
}

module.exports = FamilyAutoCompleteSearchController;
