import { setLocale } from '../util/i18n_util.js';
import { addPageAction } from '../util/new_relic.js';

export class LocaleSwitcher extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.handleLocaleClick);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.handleLocaleClick);
  }

  async handleLocaleClick(event) {
    const locale = event.target.closest('[data-class*="adobe-fonts-locale-switcher__locale-item-button"]');
    if (!locale) {
      return;
    }
    await this._setLocale(locale.value);

    addPageAction('typekit.locale-switcher', { locale: locale.value });

    this.handlePageRefresh();
  }

  async _setLocale(locale) {
    await setLocale(locale);
  }

  handlePageRefresh() {
    window.location.reload();
  }
}

customElements.define('af-locale-switcher', LocaleSwitcher);
