import { addPageAction } from '../util/new_relic.js';

export class NewRelicLinkClickTracking extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.handleLinkClick);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.handleLinkClick);
  }

  async handleLinkClick(event) {
    const subnavLink = event.target.closest('[data-click-tracking-id]');
    if (!subnavLink) {
      return;
    }

    addPageAction(subnavLink.dataset.clickTrackingId, {href: event.target.href});
  }
}

customElements.define('af-new-relic-link-click-tracking', NewRelicLinkClickTracking);
