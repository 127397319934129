export default class SearchDialogTrigger extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this._handleClick);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this._handleClick);
  }

  _handleClick = (event) => {
    // Check if the click event was on the actual element that triggers opening
    // the dialog.
    const triggerEl = event.target.closest('[trigger]');
    if (!triggerEl) {
      return;
    }

    event.preventDefault();
    return this._handleDialogTriggerClick();
  };

  async _handleDialogTriggerClick() {
    if (!this._dialog) {
      this._dialog = await this._createDialogElement();
    }

    if (this._dialog.hasAttribute('open')) {
      this._dialog.close();
    } else {
      this._dialog.show();
    }
  }

  async _createDialogElement() {
    const response = await fetch('/search/dialog');
    const parsed = new DOMParser().parseFromString(
      await response.text(), 'text/html');

    const dialog = parsed.body.firstElementChild;

    // This will prevent the whole page from scrolling if the user tries to
    // scroll when the dialog is open.
    dialog.addEventListener('touchmove', event => {
      if (!event.cancelable) {
        return;
      }

      event.preventDefault();
    });

    this.appendChild(parsed.body.firstElementChild);
    return dialog;
  }
}

customElements.define('af-search-dialog-trigger', SearchDialogTrigger);
