var CONTEXT_KEY = '/neue/context';
const UrlService = require('../../util/url_service.js').default;

/**
 * This service handles any redirect context data that's available on the page.
 *
 * For example, if a user initiated a purchase (note: we don't support purchases
 * anymore) but wasn't logged in yet, they will be redirected to the login page
 * before being redirected back to the page where they initiated the purchase.
 * The backend will handle parsing the redirect context information from the url
 * (see the RedirectContext model) and
 * will pass the information on to the front end as an object that contains the
 * information necessary to continue the purchase.
 *
 * @ngInject
 */
function RedirectContextService($q, DataService) {
  /**
   * Define the context information used to continue an action.
   *
   * @typedef {Object} Typekit.RedirectContextService.Context
   * @property {Number?} buy_font The marketplace product id for a variation
   * @property {Number?} buy_package The marketplace product id for a package
   */

  return {
    getContext: getContext,
    handleContext: handleContext,
    handleContextIfPresent: handleContextIfPresent
  };

  /**
   * Get the context object configuration from the page.
   * @returns {Promise.<Typekit.RedirectContextService.Context>}
   */
  function getContext() {
    return DataService.get(CONTEXT_KEY);
  }

  /**
   * Handles the redirect context object (if there is one) and executes the
   * correct action requested.
   *
   * @returns {Promise.<Typekit.RedirectContextService.Context>}
   */
  function handleContext() {
    return $q(function(resolve) {
      getContext().then(function(context) {
        UrlService.removeSearchParam('ctx');

        // We used to handle context related to purchases here, but without
        // Marketplace we're no longer doing that.
        //
        // Leaving this in place for now in case there are other contexts we'd
        // like to handle in the future.
        //
        // Examples:
        //
        // if (context.buy_package) {
        //   MarketplaceService.purchasePackageById(context.buy_package);
        // }
        //
        // if (context.buy_font) {
        //   MarketplaceService.purchaseVariationById(context.buy_font);
        // }

        resolve(context);
      });
    });
  }

  /**
   * Executes the action requested only if the context object is present.
   *
   * @returns {Promise.<Typekit.RedirectContextService.Context>}
   */
  function handleContextIfPresent() {
    return $q(function(resolve, reject) {
      getContext().then(function(context) {
        if (!UrlService.getSearchParam('ctx')) {
          reject();
        }

        UrlService.removeSearchParam('ctx');

        resolve(context);
      });
    });
  }
}

module.exports = RedirectContextService;
