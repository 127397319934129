import '@spectrum-web-components/theme/sp-theme.js';
import '@spectrum-web-components/theme/theme-lightest.js';
import '@spectrum-web-components/theme/scale-medium.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-cclibrary.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-checkmark.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-code.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-heart.js';

import '@adobe-fonts/site/components/feedback-button/FeedbackButton.js';
import '@adobe-fonts/site/components/flyout-navigation/FlyoutNavigation.js';
import '@adobe-fonts/site/components/flyout/Flyout.js';
import '@adobe-fonts/site/components/navbar-header/NavbarHeader.js';
import '@adobe-fonts/site/components/navbar-subnav/NavbarSubnav.js';
import '@adobe-fonts/site/components/search-bar/SearchBar.js';
import '@adobe-fonts/site/components/side-navigation/SideNavigation.js';

import('../util/visual_search.js').then(function(visualSearch) {
  visualSearch.init();
});

import '../components/LocaleSwitcher.js';
import '../components/SearchDialogTrigger.js';
import '../components/UniversalNav.js';
import '../components/NewRelicLinkClickTracking.js';

void import('@adobe-fonts/site/components/libraries/save-library-icon/SaveLibraryIcon.js');
